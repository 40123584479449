import React from 'react'

const Footer = () => (
    <footer>
        <div className="bg-boulder bg-gradient-to-b from-boulder to-aldo text-white text-center">
            <div className="container mx-auto px-8 md:px-12 lg:px-20 py-8 relative font-light text-white">
                <p className="max-w-2xl mt-4 mx-auto font-light text-sm">
                    The MONOPOLY name and logo, the distinctive design of the
                    game board, the four corner squares, the MR. MONOPOLY name
                    and character, as well as each of the distinctive elements
                    of the board and playing pieces are trademarks of Hasbro for
                    its property trading game and game equipment and are used
                    with permission. © 1935, 2021 Hasbro. All Rights Reserved.
                </p>
                <div className="flex flex-col md:flex-row items-center justify-center mt-4">
                    <a href="/terms" className="inline-block px-4 underline">
                        Terms and Conditions
                    </a>{' '}
                    <span className="hidden md:inline">|</span>{' '}
                    <a href="/privacy" className="inline-block px-4 underline">
                        Privacy Policy
                    </a>
                </div>
            </div>
        </div>
    </footer>
)

export default Footer
