import React from 'react'

import DefaultHeader, { BasicHeader } from '../Header'
import Browser from '../Browser'
import Meta from '../Meta'
import Footer from '../Footer'
import Lightbox from '../Lightbox'
import CookieBanner from '../CookieBanner'

const Root = ({ header = 'default', children }) => (
    <Browser>
        <Meta
            title="Play MONOPOLY with Gordon Ramsay"
            description="`Bankrupt Gordon Ramsay with MONOPOLY's in-game video chat and be the first to discover new Community Chest cards!`"
        />
        {header === 'default' ? <DefaultHeader /> : null}
        {header === 'basic' ? <BasicHeader /> : null}
        {children}
        <Lightbox />
        <CookieBanner />
        <Footer />
    </Browser>
)

export default Root
