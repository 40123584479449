import React from 'react'

import logo from '../../../images/global/logo.png'
import cloud1 from '../../../images/global/cloud1.png'
import cloud2 from '../../../images/global/cloud2.png'
import cloud3 from '../../../images/global/cloud3.png'
import cloud4 from '../../../images/global/cloud4.png'

import { useLightboxState } from '../../../context'

import Link from '../../Link'

import balloon from '../balloon.png'

const DefaultHeader = () => {
    const { setLightboxOpen, setLightboxVideo } = useLightboxState()

    const handleVideoClick = () => {
        setLightboxVideo({ providerUid: '581175148' })
        setLightboxOpen(true)
    }

    return (
        <div className="min-h-screen flex flex-col items-center justify-center relative pb-48 lg:pb-56 xl:pb-72 text-center bg-gradient-to-b from-blue-dark to-blue-light overflow-hidden">
            <div className="container mx-auto px-8 md:px-12 lg:px-20">
                <Link to="/">
                    <img
                        src={logo}
                        className="inline-block w-full max-w-2xl h-2xl:max-w-4xl mt-4 h-auto select-none"
                    />
                </Link>
                <h1 className="max-w-lg mx-auto mt-8 font-light text-lg text-white">
                    Bankrupt Gordon Ramsay via in-game video chat and be one of
                    the first to discover new Community Chest cards!
                </h1>
                <button
                    className="inline-flex items-center justify-center py-3 px-12 md:px-20 mt-8 font-display font-bold text-xl md:text-2xl text-white uppercase bg-gradient-to-b from-red-light to-red-dark hover:from-black hover:to-boulder rounded-full leading-none cursor-pointer select-none"
                    onClick={handleVideoClick}
                >
                    <span className="block transform -translate-y-0.5">
                        How it works
                    </span>
                </button>
            </div>

            <img
                src={cloud1}
                className="absolute w-60 md:w-72 lg:w-96 xl:w-128 bottom-0 -left-32 z-10"
            />
            <img
                src={cloud2}
                className="absolute w-40 md:w-60 lg:w-72 xl:w-80 bottom-8 md:bottom-12 left-12 md:left-20 lg:left-32 xl:left-56 z-20"
            />
            <img
                src={cloud3}
                className="absolute w-48 md:w-60 lg:w-80 xl:w-96 bottom-8 right-20 md:right-32 lg:right-56 xl:right-72 z-10"
            />
            <img
                src={balloon}
                className="absolute w-20 md:w-32 xl:w-40 bottom-10 md:bottom-4 lg:bottom-8 xl:bottom-16 right-52 md:right-72 lg:right-112 xl:right-136 z-20"
            />
            <img
                src={cloud4}
                className="absolute w-56 md:w-72 lg:w-96 xl:w-120 bottom-0 -right-12 z-30"
            />
        </div>
    )
}

export default DefaultHeader
