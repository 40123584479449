import React, { useState, useEffect } from 'react'
import { useLocation } from '@reach/router'
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'
import Cookies from 'js-cookie'

import Settings from './components/Settings'

import './style.scss'

const CookieBanner = () => {
    const [showBanner, setShowBanner] = useState(false)
    const [showSettings, setShowSettings] = useState(false)
    const location = useLocation()

    useEffect(() => {
        setShowBanner(Cookies.get('gatsby-gdpr-responded') !== 'true')
    }, [])

    useEffect(() => {
        initTracking()
    }, [Cookies.get('gatsby-gdpr-responded')])

    const initTracking = () => {
        initializeAndTrack(location)
    }

    const handleAccept = () => {
        Cookies.set('gatsby-gdpr-ga', true, { expires: 365 })
        handleCloseAll()
    }

    const handleDecline = () => {
        Cookies.remove('gatsby-gdpr-ga')
        handleCloseAll()
    }

    const handleCloseAll = () => {
        setShowSettings(false)
        setShowBanner(false)

        Cookies.set('gatsby-gdpr-responded', true, { expires: 365 })
    }

    return (
        <>
            <div
                className={`cookie-banner ${
                    showBanner && !showSettings ? 'block' : 'hidden'
                } w-full p-8 fixed bottom-4 sm:bottom-8 right-4 sm:right-8 text-center md:text-left bg-white shadow-xl z-50`}
            >
                <p className="mb-4 font-light text-sm">
                    Our site uses cookies and similar technologies. Some are
                    essential and some improve the experience. Please click
                    ‘accept’ to accept all cookies. Please click ‘decline’ to
                    keep all cookies off except those that are necessary.
                </p>
                <div className="flex flex-col md:flex-row items-center">
                    <div className="flex items-center">
                        <button
                            type="button"
                            onClick={() => handleAccept()}
                            className="inline-flex px-4 py-2 bg-red-light hover:bg-transparent border border-red-light font-display font-bold uppercase text-white hover:text-red-light rounded"
                        >
                            <span className="block transform -translate-y-0.5">
                                Accept
                            </span>
                        </button>
                        <button
                            type="button"
                            onClick={() => handleDecline()}
                            className="inline-flex px-4 py-2 ml-4 bg-aldo border border-aldo text-white font-display font-bold uppercase rounded"
                        >
                            <span className="block transform -translate-y-0.5">
                                Decline
                            </span>
                        </button>
                    </div>
                    <button
                        type="button"
                        onClick={() => setShowSettings(true)}
                        className="mt-4 md:mt-0 md:ml-4 text-sm font-bold text-blue-light"
                    >
                        Configure
                    </button>
                </div>
            </div>
            {showSettings && (
                <Settings
                    {...{
                        handleAccept,
                        handleDecline,
                        initTracking,
                        handleCloseAll,
                    }}
                />
            )}
        </>
    )
}

export default CookieBanner
