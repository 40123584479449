import React from 'react'

// import skull from '../../images/global/skull.svg'

import { useBrowserState } from '../../context'

import SVG from '../SVG'

const Browser = ({ children }) => {
    const { browser } = useBrowserState()

    return (
        <>
            {children}
            {browser === 'ie' && (
                <div
                    className="browser flex flex-col items-center justify-center w-screen h-screen fixed top-0 left-0 text-center z-50 overflow-hidden"
                    style={{ backgroundColor: '#f8f8f8', zIndex: 9999 }}
                >
                    <div className="container mx-auto px-10">
                        {/* <SVG
                            src={skull}
                            className="w-12 h-auto mx-auto text-red-light"
                            style={{ color: '#94c127' }}
                        /> */}
                        <h1 className="mt-8 mb-4 text-3xl lg:text-4xl text-black font-light">
                            This browser is no longer supported
                        </h1>
                        <p className="max-w-lg w-full mx-auto">
                            Please switch to a supported browser to continue
                            using the website:
                        </p>
                        <div className="flex flex-col sm:flex-row items-center justify-center mt-8">
                            <a
                                href="https://www.google.com/intl/en_uk/chrome/"
                                target="_blank"
                                rel="noopener nofollow noreferrer"
                                className="inline-flex px-4 py-2 bg-red-light font-normal rounded"
                                style={{
                                    color: '#ffffff',
                                    backgroundColor: '#94c127',
                                }}
                            >
                                Google Chrome
                            </a>
                            <a
                                href="https://www.mozilla.org/en-GB/firefox/new/"
                                target="_blank"
                                rel="noopener nofollow noreferrer"
                                className="inline-flex mt-2 sm:mt-0 sm:ml-2 px-4 py-2 bg-transparent font-normal rounded"
                                style={{
                                    color: '#ffffff',
                                    backgroundColor: '#94c127',
                                }}
                            >
                                Firefox
                            </a>
                            <a
                                href="https://www.microsoft.com/en-us/edge"
                                target="_blank"
                                rel="noopener nofollow noreferrer"
                                className="inline-flex mt-2 sm:mt-0 sm:ml-2 px-4 py-2 bg-transparent font-normal rounded"
                                style={{
                                    color: '#ffffff',
                                    backgroundColor: '#94c127',
                                }}
                            >
                                Microsoft Edge
                            </a>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default Browser
