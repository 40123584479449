import React, { useState } from 'react'
import Cookies from 'js-cookie'

import Button from '../../../Button'
import Toggle from '../../../Inputs/Toggle'

const CookieSettings = ({
    handleAccept,
    handleDecline,
    initTracking,
    handleCloseAll,
}) => {
    const [trackAnalytics, setTrackAnalytics] = useState(
        Cookies.get('gatsby-gdpr-ga') === 'true'
    )

    const handleToggle = () => {
        Cookies.set('gatsby-gdpr-responded', true, { expires: 365 })

        setTrackAnalytics((prevState) => {
            if (prevState) {
                Cookies.remove('gatsby-gdpr-ga')
            } else {
                Cookies.set('gatsby-gdpr-ga', true, {
                    expires: 365,
                })
            }

            return !prevState
        })

        initTracking()
    }

    return (
        <div className="w-full sm:w-96 h-full p-8 fixed top-0 left-0 bg-white shadow-2xl overflow-y-scroll z-50">
            <h3 className="mb-4 text-xl md:text-2xl font-display font-bold uppercase text-red-light">
                This site uses cookies to store information on your computer.
            </h3>
            <div className="mb-4 font-light text-sm">
                <p>
                    Some of these cookies are essential, while others help us to
                    improve your experience by providing insights into how the
                    site is being used.
                </p>
                <p className="mt-4">
                    You can find out how to manage your preferences in relation
                    to our use of cookies at Cookie Policy
                </p>
            </div>
            <div className="flex items-center mt-4">
                <button
                    type="button"
                    onClick={() => handleAccept()}
                    className="inline-flex px-4 py-2 bg-red-light hover:bg-transparent border border-red-light text-white hover:text-red-light font-bold font-display uppercase rounded"
                >
                    <span className="block transform -translate-y-0.5">
                        Accept
                    </span>
                </button>
                <button
                    type="button"
                    onClick={() => handleDecline()}
                    className="inline-flex px-4 py-2 ml-4 bg-aldo border border-aldo text-white font-bold font-display uppercase rounded"
                >
                    <span className="block transform -translate-y-0.5">
                        Decline
                    </span>
                </button>
            </div>
            <div className="pt-8 mt-8 border-t-4 border-gallery">
                <div className="mb-4 flex items-center justify-between">
                    <h4 className="font-display font-bold uppercase text-lg md:text-xl text-blue-light">
                        Necessary Cookies
                    </h4>
                </div>
                <p className="font-light text-sm">
                    Necessary cookies enable core functionality such as page
                    navigation and access to secure areas. The website cannot
                    function properly without these cookies, and can only be
                    disabled by changing your browser preferences.
                </p>
            </div>
            <div className="pt-8 mt-8 border-t-4 border-gallery">
                <div className="mb-4 flex items-center justify-between">
                    <h4 className="font-display font-bold uppercase text-lg md:text-xl text-blue-light">
                        Analytical Cookies
                    </h4>
                    <Toggle
                        active={trackAnalytics}
                        toggleActive={() => handleToggle()}
                    />
                </div>
                <p className="font-light text-sm">
                    Analytical cookies are non-essential cookies which help us
                    to improve our website by collecting and reporting
                    information on its usage. We use these cookies so we can
                    better understand how you engage with our website, marketing
                    campaigns and products. The information we collect is
                    anonymised.
                </p>
            </div>
            <button
                type="button"
                onClick={() => handleCloseAll()}
                className="inline-flex px-4 py-2 mt-8 md:mt-12 bg-black hover:bg-transparent border border-black text-white hover:text-black font-display font-bold uppercase rounded"
            >
                <span className="block transform -translate-y-0.5">Close</span>
            </button>
        </div>
    )
}

export default CookieSettings
