import React from 'react'
import { Helmet } from 'react-helmet'

import favicon from '../../images/gatsby-icon.png'
import shareImage from '../../images/share.jpg'

const Meta = ({ title, description }) => (
    <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />

        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={shareImage} />

        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={shareImage} />
        <meta name="twitter:card" content="summary_large" />

        <link rel="icon" type="image/png" href={favicon} />
    </Helmet>
)

export default Meta
