import React from 'react'
import BackgroundImage from 'gatsby-background-image'

import arrowRight from '../../images/global/arrow-right.svg'
import cross from '../../images/global/cross.svg'

import { useLightboxState } from '../../context'

import SVG from '../SVG'

import './style.scss'

const Lightbox = () => {
    const {
        lightboxOpen,
        setLightboxOpen,
        activeLightboxImage,
        setActiveLightboxImage,
        lightboxImages,
        setLightboxImages,
        lightboxVideo,
        setLightboxVideo,
    } = useLightboxState()

    const handleLightboxClose = () => {
        setLightboxOpen(false)

        setTimeout(() => {
            setLightboxImages([])
            setLightboxVideo(false)
        }, 250)
    }

    return (
        <div
            className={`grid grid-cols-12 w-full h-full p-10 pb-20 xl:p-20 fixed top-0 left-0 bg-black text-center text-white transition-all ${
                lightboxOpen
                    ? 'opacity-100 visible pointer-events-auto'
                    : 'opacity-0 invisible pointer-events-none'
            } z-50`}
        >
            {lightboxImages.length > 0 && (
                <div
                    className="hidden md:flex items-center justify-center cursor-pointer"
                    onClick={() =>
                        setActiveLightboxImage(
                            Math.max(0, activeLightboxImage - 1)
                        )
                    }
                >
                    <SVG
                        src={arrowRight}
                        className={`transform origin-center rotate-180 ${
                            activeLightboxImage === 0
                                ? 'opacity-20 pointer-events-none'
                                : 'opacity-100'
                        }`}
                    />
                </div>
            )}
            <div className="col-span-12 md:col-span-10 col-start-1 md:col-start-2 lightbox__inner flex items-center justify-center mx-auto w-full h-full top-0 left-0 relative">
                {lightboxImages.length > 0 && (
                    <>
                        {lightboxImages.map(({ full, alt }, i) => (
                            <>
                                <div
                                    className={`lightbox__image w-full h-full absolute top-0 left-0 transition-all duration-500 ${
                                        i === activeLightboxImage
                                            ? 'opacity-100 visible pointer-events-auto'
                                            : 'opacity-0 invisible pointer-events-none'
                                    }`}
                                    data-alt={alt}
                                >
                                    <BackgroundImage
                                        fluid={full}
                                        className="w-full h-full absolute top-0 left-0 bg-contain bg-center bg-no-repeat"
                                        style={{ position: null }}
                                    />
                                </div>
                            </>
                        ))}
                    </>
                )}
                {lightboxVideo && (
                    <div className="w-full pt-9/16 relative">
                        <iframe
                            src={`https://player.vimeo.com/video/${lightboxVideo?.providerUid}?autoplay=1`}
                            width="640"
                            height="360"
                            frameborder="0"
                            allow="autoplay; fullscreen; picture-in-picture"
                            allowfullscreen
                            className="w-full h-full absolute top-0 left-0"
                        ></iframe>
                    </div>
                )}
            </div>
            {lightboxImages.length > 0 && (
                <div
                    className="hidden md:flex items-center justify-center cursor-pointer"
                    onClick={() =>
                        setActiveLightboxImage(
                            Math.min(
                                lightboxImages.length - 1,
                                activeLightboxImage + 1
                            )
                        )
                    }
                >
                    <SVG
                        src={arrowRight}
                        className={`transform ${
                            activeLightboxImage === lightboxImages.length - 1
                                ? 'opacity-20 pointer-events-none'
                                : 'opacity-100'
                        }`}
                    />
                </div>
            )}
            <SVG
                src={cross}
                className="absolute top-10 right-10"
                clickHandler={() => handleLightboxClose()}
            />
        </div>
    )
}

export default Lightbox
